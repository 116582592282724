<template>
  <div>
    <q-btn
      flat
      size="12px"
      color="orange"
      icon="chevron_right"
      @click="rowClickDetail(props.row, props.rowIndex)" 
    />
    <c-dialog :param="popupOptions"></c-dialog>
    <q-dialog v-model="dialogDetail" position="bottom">
      <q-card style="width: 100%">
        <q-card-section class="row">

          <slot name="customArea" v-bind:props="props" v-bind:col="col"></slot>

        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: 'mobileDetail',
  props: {
    col: {
      type: Object,
      default: function() {
        return {
          tbmAttendeeId: '',
        }
      }
    },
    props: {
      type: Object,
      default: function() {
        return { }
      }
    },
    rowIndex: Number
  },
  data() {
    return {
      dialogDetail: false,
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
    },
    rowClickDetail(_row, _idx) {
      this.props.row = _row; // row 데이터를 저장
      this.rowIndex = _idx; // rowIndex를 저장
      this.dialogDetail = true;
    },
    dialogClose() {
      this.dialogDetail = false;
    },
    dialogSubmit(){
      this.$emit('closeAttendeeDetail', this.props.row, this.rowIndex)
      this.$set(this.props.row, 'editFlag', 'U');
      this.$set(this.props.row, 'chgUserId', this.$store.getters.user.userId);
      this.dialogDetail = false;
    }
  }
};
</script>